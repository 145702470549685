@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import '~react-toastify/dist/ReactToastify.min.css';
body{font-family:"Open Sans", sans-serif;font-size:16px;color:#2b2b2b;}
img{max-width:100%;}
.header{box-shadow:0 0 10px rgba(0, 0, 0, 0.15);background:#fff;}
.logo_toggle_wrap .logo_wrap{padding:10px 0;}
.header_nav{height:100%;}
.header_nav ul{list-style:none;margin:0;justify-content:flex-end;padding:0;height:100%;}
.header_nav ul li{text-align:center;display:flex;align-items:center;}
.header_nav ul li a{text-decoration:none;color:#b10d28;text-transform:uppercase;line-height:80px;padding:0 15px;border-left:1px solid #f2f2f2;background:#fff;transition:all 0.3s ease-in-out;font-size:16px;}
.header_nav ul li a:hover, .header_nav ul li.active a{background:#b10d28;color:#fff;}
.logo_toggle_wrap .logo_wrap img{max-height:60px;}
.mobile_menu_toggle{display:none;}
.hero_blk{padding:45px 0 150px 0;background:#222222;border-top:7px solid #E5E5E5;}
.hero_txt_blk h1{font-size:36px;color:#777777;}
.hero_txt_blk p:last-child{margin-bottom:0;}
.hero_txt_blk p{color:#777777;}
.contact_btn_wrap a{display:inline-block;padding:6px 12px;font-size:14px;margin:5px;text-shadow:1px 1px 1px rgba(0, 0, 0, .5);text-transform:uppercase;letter-spacing:0.8px;background:#b10d28;color:#ffffff;box-shadow:0 5px 0 #97051d;text-decoration:none;border-radius:5px;transition:all 0.3s ease-in-out;}
.contact_btn_wrap a span i{margin-right:10px;}
.contact_btn_wrap a:hover{background:#97051d;}
.lead_form_wrap .lead_form{display:flex;align-items:center;justify-content:space-between;}
.lead_form_blk{position:relative;top:-80px;}
.lead_form_wrap{padding:25px;background:#fff;box-shadow:0 0 10px rgba(0, 0, 0, 0.15);margin:0 auto;border-radius:20px;}
.lead_form_wrap h3{font-size:24px;margin-bottom:10px;font-weight:600;}
.lead_form_wrap h3 span{color:#b10d28;}
.lead_form .form-group{margin-right:20px;width:100%;}
.small_txt{font-size:12px;color:#696868;margin-top:10px;margin-bottom:5px;}
.red_btn, .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active, .btn-primary:focus, .btn:focus-visible{background:#b10d28;border:1px solid #b10d28;border-radius:100px;color:#fff;padding:7px 20px;text-decoration:none;text-transform:capitalize;}
.red_btn:hover{background:#fff;color:#b10d28;border-color:#b10d28;}
.red_outline_btn{background:#fff;border:1px solid #b10d28;border-radius:100px;color:#b10d28;padding:7px 20px;text-decoration:none;text-transform:capitalize;}
.red_outline_btn:hover{background:#b10d28;color:#fff;border-color:#b10d28;}
.btn.disabled, .btn:disabled, fieldset:disabled .btn{color:#727272;background-color:#e5e5e5;border-color:#e5e5e5;}
.serv_box_inr{text-align:center;background:#fff;box-shadow:0 0 10px rgba(0, 0, 0, 0.15);border-radius:10px;padding:20px;position:relative;z-index:1;}
.serv_icon{display:flex;justify-content:center;align-content:center;border:1px solid #fcbcc7;width:70px;height:70px;border-radius:100px;padding:15px;margin:-60px auto 0 auto;background:#fed0d8;}
.serv_content{margin-top:20px;}
.serv_content h4{font-size:18px;transition: 0.5s;font-weight:600;}
.serv_content p{font-size:16px;transition: 0.5s;}
.serv_content p:last-child{margin-bottom:0;}
.serv_box .serv_box_inr::after{position:absolute;left:0px;bottom:0px;content:"";width:0px;height:0px;background:#b10d28;z-index:-1;transition:0.5s;}    
.services_blk .row > div, .services_blk .serv_box{display:flex;}
.info_blk{padding:50px 0;}
.info_ad_box{text-align:center;background:#3f3f3f url('../public/img/ad-bg.jpg')no-repeat left center;padding:20px 15px;border-radius:10px;position:relative;overflow:hidden;background-size:cover;}
.info_ad_box p{margin-bottom:0;color:#fff;font-size:34px;font-weight:700;position: relative;
  z-index: 1;}
.info_ad_box p span{color:#279cf7;}
.info_ad_box::after{content:'';width:100%;height:100%;position:absolute;left:0;top:0;background:rgba(1, 30, 72, 0.8);}
.footer_top{overflow:hidden;padding:50px 0;border-bottom:1px solid #0f0f0f;background:#222;color:#aaa;}
.footer ul{margin-bottom:0;}
.footer_menu_title h3{color:#fff;text-transform:uppercase;position:relative;padding:0 0 10px;font-size:18px;}
.footer_top .footer_menu a::before{display:inline-block;margin-right:7px;color:#5b5b5b;content:"\f105";font-family:FontAwesome;}
.footer_top .footer_menu{list-style:none;margin:0;padding:0;}
.footer_top .footer_menu li{padding:0;border-bottom:1px solid rgba(255, 255, 255, 0.05);transition:all 0.3s ease 0s;}
.footer_top .footer_menu li:hover{padding-left:10px;}
.footer_top .footer_menu li a{color:#d2d2d2;font-size:14px;text-decoration:none;display:inline-block;padding:10px 0;text-transform:uppercase;}
.footer_content p{font-size:14px;margin-bottom:20px;color:#d2d2d2;}
.footer_content p:last-child{margin-bottom:0;}
.footer_top a{color:#d2d2d2;text-decoration:none;}
.footer_content{padding-right:20px;}
.footer_top .footer_menu li:hover a, .footer_top .footer_menu li:hover a::before, .footer_top a:hover{color:#fff;}
.social_media_links ul{display:flex;list-style:none;margin-bottom:0;padding-left:0;}
.social_media_links ul li a{align-items:center;background-color:#b10d28;border:1px solid #b10d28;border-radius:50%;display:flex;height:40px;justify-content:center;width:40px;}
.social_media_links ul li{margin-right:10px;}
.social_media_links ul li:last-child{margin-right:0px;}
.social_media_links ul li a:hover{background-color:#fff;border:1px solid #b10d28;}
.social_media_links ul li a:hover svg path{fill:#b10d28;}
.footer_bottom{overflow:auto;padding:15px 0;border-top:1px solid #363636;background:#1D1D1D;}
.copyright_blk p{margin-bottom:0;color:#777;font-size:14px;}
.legal_links ul{margin-bottom:0;list-style:none;display:flex;align-items:center;padding:0;}
.legal_links ul li{margin-left:15px;}
.legal_links ul li a{font-size:14px;color:#777;text-decoration:none;}
.legal_links ul li a:hover{color:#fff;}
.ft_btm_content{display:flex;justify-content:space-between;align-items:center;}
/*Footer end*/
/*Sticky header start*/
.stickyHeader{background:#fff;position:fixed;z-index:9;width:100%;box-shadow:0 0 10px rgba(0, 0, 0, 0.15);}
@keyframes topMenuSlideDown {
    from{top:-80px;opacity:0;}
    to{top:0px;opacity:1;}
}
.stickyHeader {top: 0px;-webkit-animation: topMenuSlideDown .5s ease-in-out;-moz-animation: topMenuSlideDown .5s ease-in-out;-ms-animation: topMenuSlideDown .5s ease-in-out;-o-animation: topMenuSlideDown .5s ease-in-out;animation: topMenuSlideDown .5s ease-in-out;}
.stickyHeader+main{padding-top:80px;}
/*Sticky header end*/
/*Inner pages hero start*/
.inner_hero_blk{min-height:350px;display:flex;position:relative;align-items:center;border-top:7px solid #E5E5E5;}
.inner_hero_blk .container{position:relative;z-index:1;}
.alarm_systems_pg{background:#e2e2e4 url('../public/img/alarm-hero-img.jpg')no-repeat right top;background-size:cover;}
.camera_systems_pg{background:#e2e2e4 url('../public/img/camera-hero-img.jpg')no-repeat right top;background-size:cover;}
.home_automation_pg{background:#e2e2e4 url('../public/img/home-automation-hero-img.jpg')no-repeat right top;background-size:cover;}
.help_pg{background:url('../public/img/help-hero-img.jpg')no-repeat right top;background-size:cover;}
.contact_pg{background:url('../public/img/contact-hero-img.jpg')no-repeat right top;background-size:cover;}
.legal_pg{background:url('../public/img/legal-hero-img.jpg')no-repeat right top;background-size:cover;min-height:250px;padding:100px 0 150px 0;}
.inner_hero_blk::after{content:'';width:100%;height:100%;position:absolute;left:0;top:0;background:rgba(0, 0, 0, 0.6);z-index:0;}
.inner_hero_txt_blk h1{font-size:44px;text-transform:uppercase;font-weight:700;color:#fff;margin-bottom:0;}
.inner_main_blk{padding-top:50px;}
.sidebar_heading{background:#b10d28;border-radius:10px;padding:10px;margin-bottom:15px;}
.sidebar_heading h3{font-size:24px;color:#fff;margin-bottom:0;font-weight:600;}
.sidebar_wrap .lead_form_wrap{padding:20px;border-radius:0;box-shadow:none;}
.sidebar_wrap .lead_form_wrap h3{font-size:18px;}
.sidebar_wrap .lead_form_wrap .lead_form{flex-direction:column;align-items:flex-start;}
.sidebar_wrap .lead_form .form-group{margin-right:0;margin-bottom:10px;}
.sidebar_item .side_blk_content{box-shadow:0 0 10px rgba(0, 0, 0, 0.15);border-radius:10px;overflow:hidden;}
.license_blk{padding:20px;border-top:1px solid #f2f2f2;}
.license_blk p{margin-bottom:10px;font-size:14px;}
.license_blk p span{font-weight:600;}
.license_blk p:last-child{margin-bottom:0;}
.sidebar_wrap .sidebar_item{margin-bottom:40px;}
.sidebar_wrap .sidebar_item:last-child{margin-bottom:0;}
.video_wrap video{border-radius:10px;overflow:hidden;}
.honeywell_links_wrap ul{margin-bottom:0;padding-left:0px;list-style:none;}
.honeywell_links_wrap ul li{margin-bottom:10px;}
.honeywell_links_wrap ul li:last-child{margin-bottom:0;}
.honeywell_links_wrap ul li a{text-decoration:none;background:#fcafbc;display:block;border-radius:5px;color:#b10d28;text-align:center;padding:7px;font-weight: 600;transition:0.3s;}
.honeywell_links_wrap ul li a:hover{background:#b10d28;color:#fff;transition:0.3s;}
.main_content_blk h3{font-size:30px;}
.inner_main_content .faq_box{background:#f2f2f2;padding:25px;border-radius:10px;margin-top:40px;}
.inner_main_content .faq_box .faq_sec_title h3{font-size:30px;margin-bottom:10px;}
.inner_main_content .faq_box .accordion-button{font-weight:600;}
.inner_main_content .faq_box .accordion-button:not(.collapsed){color:#fff;background-color:#0a458e;}
.inner_main_content .faq_box .accordion-button::after{background-image:url("../public/img/chevron-down.svg");background-position: right center;}
.inner_main_content .faq_box .accordion-button:not(.collapsed)::after{background-image:url("../public/img/chevron-down-white.svg");background-position: right center;}
.main_content_blk img{border-radius:10px;margin-bottom:40px;margin-top:20px;}
.more_info_blk{margin-top:50px;}
.main_content_blk ul{margin-bottom:0;}
.main_content_blk ul li a{color:#1a0dab;text-decoration:none;}
.camera_systems_pg .inner_hero_txt_blk, .legal_pg .inner_hero_txt_blk h1{text-align:center;}
.sidebar_wrap .app_guide_wrap ul li img{width:100%;max-width:415px;margin:0 auto;border-radius:10px;}
.more_info_blk h3 a{text-decoration:none;color:#B10D28;}
.help_vid_blk{display:flex;justify-content:space-between;flex-wrap:wrap;align-items:flex-start;margin-top:30px;}
.help_vid_blk .vid_item{width:calc(50% - 10px);margin-bottom:20px;}
.help_vid_blk .vid_item h5{font-size:16px;margin-bottom:10px;}
.help_pg + .inner_main_blk, .contact_pg + .inner_main_blk{padding-bottom:50px;}
.help_vid_blk .vid_item iframe{border-radius:10px;}
.contact_us_blk{background:#f2f2f2;border-radius:10px;padding:30px;}
.contact_form .form-group{margin-bottom:15px;}
.contact_form .form-group textarea{resize:none;}
.legal_main .inner_main_content{background:#f2f2f2;padding:40px;border-radius:10px;position:relative;z-index:1;margin-bottom:50px;}
.legal_main .inner_main_content .main_content_blk h4{margin:25px 0 10px 0;}
.legal_main .inner_main_content .main_content_blk p:last-child{margin-bottom:0;}
.legal_main .inner_main_content .main_content_blk h4:first-child{margin-top:0;}
.legal_main{margin-top:-150px;}
.legal_main .main_content_blk ul{margin-bottom:20px;}
/*Inner pages hero end*/
/*404 page start*/
.sales-error-box{background: #b10d28;height: 100vh;display: flex;justify-content: center;align-items: center;padding: 10px;}
.sales-error-box .card-body {width:100%;padding:40px;max-width:550px;margin:0 auto;background: #fff;border-radius: 10px;}
.sales-error-box .card {background: transparent;border: none;}
.sales-error-box .card-logo {width: 250px;margin: 0 auto;text-align:center;}
.sales-error-box .card-title {margin-top:10px;text-align: center;}
.sales-error-box .card-title h1 {font-size:10rem;font-weight: 600;text-align: center;color: #b10d28 !important;margin-bottom: 0;}
/*404 page end*/
/*Thank you page start*/
.thank_you_blk{padding:50px;background:#f2f2f2;}
.thank_you_content{text-align:center;padding:40px;background:#fff;max-width:850px;margin:0 auto;border-radius:20px;}
.thank_you_content h1{font-size:34px;margin-bottom:30px;}
.thank_you_content img{max-width:400px;margin-bottom:30px;}
.thank_you_content p:last-child{margin-bottom:0;}
.thank_you_content p a{font-weight:500;color:#1a0dab;}
.success_icon{margin-bottom:30px;}
.success_icon i{color:#008000;font-size:60px;}
/*Thank you page end*/
/*Payments pages start*/
.payment_link, .payment_summary{background:#f2f2f2;}
.payment_link .footer{display:none;}
.payment_link_blk{padding:20px 0 50px 0;}
.payment_link_content{max-width:690px;margin:0px auto 50px auto;background:#fff;padding:40px;border-radius:25px;}
.payment_link_content .form-group label{font-size:16px;font-weight:600;margin-bottom:5px;}
.or_divider{text-align:center;margin:15px 0 10px 0;font-size:30px;}
.payment_link_content .small{color:#7b7b7b;line-height:18px;display:block;margin-top:5px;}
.payment_link_content .btn_blk{text-align:center;margin:30px auto;}
.payment_link_content .alert{margin-bottom:0;}
.payment_link_blk .copyright_blk{text-align:center;}
.success_tick{width: 60px;height: 60px;border-radius: 100px;background: #1cb765;display: flex;justify-content: center;align-items: center;padding: 10px;margin:10px auto 20px auto;}
.success_tick img {max-width: 30px;}
.modal-header {background:#b10d28;padding:8px 20px;}
.modal-header .modal-title {color:#fff;font-size:20px;}
.email_sms_popup .modal-body{text-align:center;}
.modal-body{padding:20px;}
.modal-header .btn-close{background:url('../public/img/close_btn_bg.png') no-repeat center center;opacity:1;background-size:14px;}
.payment_link .modal-body p{text-align:center;}
.payment_link .modal-body p span{font-weight:500;}
.payment_link .modal-body .ok_btn_blk{text-align:center;margin-bottom:10px;}
.payment_summary_blk{margin:20px auto 50px auto;}
.payment_summary_content{max-width:850px;margin:0px auto;background:#fff;padding:40px;border-radius:25px;}
.payment_summary_content h1{font-size:24px;}
.payment_summary_content h1 span{font-weight:600;color:#b10d28;}
.blk_title{font-size:20px;}
.order_info_blk{background:#fff;border:1px solid #ced4da;border-radius:5px;padding:20px;margin-bottom:20px;}
.order_info_blk:last-child{margin-bottom:0;}
.order_info ul{list-style:none;padding:0;margin:0;}
.order_info ul li{background:#fff;border:1px solid #ced4da;padding:15px;border-radius:5px;width:calc(33.3334% - 5px);margin-right:10px;}
.order_info ul li:last-child{margin-right:0;}
.order_info ul li:hover{background:#b10d28;border:1px solid #b10d28;}
.order_info ul li:hover .item_val, .order_info ul li:hover .item_lbl{color:#fff;}
.order_info ul li .item_val{display:block;text-align:center;font-size:20px;color:#b10d28;font-weight:500;}
.order_info ul li .item_lbl{font-size:14px;display:block;text-align:center;color:#6c7588;}
.payment_summary .footer_top{display:none;}
.address_blk{margin-bottom:15px;border-color:#ced4da;background:#f6f6f6;padding:15px;border-radius:5px;}
.address_blk p{margin-bottom:0;}
.personal_info ul{padding:0;margin:0;list-style:none;justify-content:space-between;}
.personal_info ul li{width:calc(50% - 5px);margin-right:10px;}
.personal_info ul li:last-child{margin-right:0;}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;-moz-appearance:none;appearance:none;margin:0;}
.personal_info ul li .form-label{margin-bottom:5px;}
.personal_info ul li .form-label span{color:#B10D28;}
.authorization_wrap p {margin-bottom: 0;padding-left: 25px;font-size: 14px;color: #2b2b2b;}
.authorization_wrap ul {list-style: none;display: flex;margin-bottom: 0;padding-left: 25px;}
.authorization_wrap ul li {margin-right: 10px;}
.authorization_wrap ul li:first-child {margin-right: 40px;}
.ledger_number_blk .payment_link_content{max-width:550px;}
.ledger_number_blk .payment_link_content .btn_blk{margin-bottom:0;}
.payment_link_content .alert a{color:#052c65;}
.contact_method_blk h5, .account_number_blk h5{font-size:20px;font-weight:600;margin-bottom:7px;}
.contact_method_radio .form-check{display:inline-flex;align-items:center;}
.contact_method_radio .form-check label{font-weight:600;font-size:20px;margin-left:10px;}
.form-check-input[type=radio]{margin-top:0;}
.form-check-input:checked{background-color:#fcd3da;border-color:#B10D28;}
.form-check-input:checked[type=radio]{background-image:url('../public/img/radio-select.png');background-size:8px;}
.form-check-input:focus{box-shadow:0 0 0 .25rem rgba(177, 13, 40, .25);}
.contact_method_blk{margin-bottom:10px;}
.bs-stepper .step-trigger{display:flex;padding: 8px;font-size: 1rem;font-weight: 500;color: #6c757d;text-align: center;text-decoration: none;background-color: transparent;border: none;transition: background-color .15s ease-out, color .15s ease-out;flex-direction:column;align-items:center;justify-self:center;}
.bs-stepper .step-trigger:not(:disabled):not(.disabled) {cursor: pointer;}
.bs-stepper .step-trigger:disabled, .bs-stepper .step-trigger.disabled {pointer-events: none;opacity:1;}
.bs-stepper .step-trigger:focus {outline: none;}
.bs-stepper .step-trigger:hover {text-decoration: none;background-color: rgba(0, 0, 0, .06);}
.custom-control-input:checked~.custom-control-label::before {border-color: #B10D28;background-color: #B10D28;}
.bs-stepper-label{display:inline-block;margin:.25rem;}
.bs-stepper-header{display: flex;align-items: center;justify-content: space-between;position: relative;max-width: 690px;margin: 50px auto 0 auto;overflow: hidden;}
.bs-stepper-header::before{content:'';width:80%;height:1px;position:absolute;top:33px;left:70px;background:#a1a0a0;}
.bs-stepper-header::after{content:'';width:80%;height: 1px;position: absolute;top: 33px;left:70px;background:#B10D28;}
.bs-stepper-header .step{position: relative;z-index: 2;}
.bs-stepper-header .step button{min-width:155px;}
.bs-stepper-line, .bs-stepper .line{flex:1 0 32px;min-width:1px;min-height:1px;margin:auto;background-color:rgba(0, 0, 0, .12);}
.bs-stepper-circle{display:flex;align-content:center;justify-content:center;width:40px;height:40px;padding:.5em 0;margin:.25rem;color:#fff;background-color:#fff;border-radius:100px;border:1px solid #a1a0a0;align-items:center;}
.active .bs-stepper-circle{background-color:#b10d28;border-color:#b10d28;}
.bs-stepper-content{padding:0 20px 20px;}
.bs-stepper.vertical{display:flex;}
.bs-stepper.vertical .bs-stepper-header{flex-direction:column;align-items:stretch;margin:0;}
.bs-stepper.vertical .bs-stepper-pane, .bs-stepper.vertical .content{display:block;}
.bs-stepper.vertical .bs-stepper-pane:not(.fade), .bs-stepper.vertical .content:not(.fade){display:block;visibility:hidden;}
.bs-stepper-pane:not(.fade), .bs-stepper .content:not(.fade){display:none;}
.bs-stepper .content.fade, .bs-stepper-pane.fade{visibility:hidden;transition-duration:.3s;transition-property:opacity;}
.bs-stepper-pane.fade.active, .bs-stepper .content.fade.active{visibility:visible;opacity:1;}
.bs-stepper-pane.active:not(.fade), .bs-stepper .content.active:not(.fade){display:block;visibility:visible;}
.bs-stepper-pane.dstepper-block, .bs-stepper .content.dstepper-block{display:block;}
.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none, .bs-stepper:not(.vertical) .content.dstepper-none{display:none;}
.vertical .bs-stepper-pane.fade.dstepper-none, .vertical .content.fade.dstepper-none{visibility:hidden;}
.step_label{display:block;}
.bs-stepper-header .step .bs-stepper-circle{text-indent:100000px;}
.bs-stepper-header .step1 .bs-stepper-circle{background:#fff url('../public/img/person-grey.png') no-repeat center center;background-size:20px;}
.bs-stepper-header .step2 .bs-stepper-circle{background:#fff url('../public/img/ledger-grey.png') no-repeat center center;background-size:20px;}
.bs-stepper-header .step3 .bs-stepper-circle{background:#fff url('../public/img/payment-grey.png') no-repeat center center;background-size:20px;}
.bs-stepper-header .step1.active .bs-stepper-circle, .bs-stepper-header .step1.completed .bs-stepper-circle{background:#b10d28 url('../public/img/person-white.png') no-repeat center center;background-size:20px;}
.bs-stepper-header .step2.active .bs-stepper-circle, .bs-stepper-header .step2.completed .bs-stepper-circle{background:#b10d28 url('../public/img/ledger-white.png') no-repeat center center;background-size:20px;}
.bs-stepper-header .step3.active .bs-stepper-circle, .bs-stepper-header .step3.completed .bs-stepper-circle{background:#b10d28 url('../public/img/payment-white.png') no-repeat center center;background-size:20px;}
.bs-stepper-header .step .bs-stepper-label{font-weight:normal;font-size:14px;line-height:normal;margin:0;}
.bs-stepper-header .step.active .bs-stepper-label, .bs-stepper-header .step.completed .bs-stepper-label{font-weight:500;color:#2b2b2b;}
.payment_link_content.ledger_blk .btn_blk{margin-bottom:0;}
.success_msg{
    font-size: 20px;
    color: green;
}
.btn_blk {
    margin-top: 20px;
}
.btn_blk .red_btn{
    margin-right: 10px;
}
@media print { 
    @page {size:landscape;}  
    body, html, #wrapper{width:100%;}
    div{overflow:visible !important;}
}
@media (max-width: 520px) {
    .bs-stepper .step-trigger{flex-direction:column;padding:10px;}
    .bs-stepper-header{margin:0 -10px;text-align:center;}
    .bs-stepper-content{padding:0;}
}
@media (max-width: 400px) {
    .bs-stepper-line, .bs-stepper .line{flex-basis:20px;}
}
.saved_card_list ul{list-style: none;margin: 0;padding: 0;display: flex;justify-content: space-between;flex-wrap: wrap;}
.saved_card_list ul li{background: #f2f2f2;padding: 15px 20px;border-radius: 5px;border: 1px solid #ced4da;width: calc(50% - 5px);
margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;cursor:pointer;}
.saved_card_list ul li:hover, .saved_card_list ul li.active { border: 1px solid #b10d28;}
.add_card_btn_blk{margin-top: 10px;}
.payable_amount_blk{display:flex;justify-content:center;align-items:center;}
.payable_amount_blk .edit_btn, .payable_amount_blk .edit_btn:active{background:url('../public/img/edit-icon.png') no-repeat center center;border:none;padding:0;margin:0px 0 0 10px;outline:none;width:20px;height:20px;}
.payable_amount_blk .edit_btn:focus-visible, .sample_invoice_blk .invoice_btn:focus-visible{box-shadow:none;}
.order_info ul li:hover .payable_amount_blk .edit_btn{background:url('../public/img/edit-icon-white.png') no-repeat center center;}
.update_amount_popup .ok_btn_blk, .autopay_popup .ok_btn_blk{margin-top:20px;}
.autopay_checkbox .form-check-input, .show_email_checkbox .form-check-input{width:20px;height:20px;margin-right:10px;margin-top:0;}
.autopay_checkbox .form-check-input:checked, .show_email_checkbox .form-check-input:checked{background-color:#B10D28;border-color:#B10D28;}
.autopay_blk{margin-top:20px;}
.autopay_checkbox .form-check, .show_email_checkbox .form-check{display:flex;align-items:center;}
.form-group label{margin-bottom:5px;}
.email_sms_popup .modal-body p{margin-bottom:10px;}
.sample_invoice_blk{display:flex;align-items:center;}
.sample_invoice_blk .invoice_btn, .sample_invoice_blk .invoice_btn:active{background:url('../public/img/question-icon.png') no-repeat center center;border:none;padding:0;margin:5px 0 0 5px;outline:none;width:14px;height:14px;}
.account_number_blk{margin-bottom:15px;}
.show_email_checkbox_blk{margin-bottom:15px;}
.ok_btn_blk button{margin-right:10px;}
.ok_btn_blk button:last-child{margin-right:0;}
.form-control:disabled, .form-control[readonly]{background-color:#e9ecef;opacity:1;}
.selected_card_blk{margin-top:20px;}
.selected_card_blk .alert{margin-bottom:0;}
.selected_card_blk .alert p:last-child{margin-bottom:0;}
/*Loading start*/
.modal-open{overflow: auto !important; padding-right: 0 !important;}
.loading .loading_wrap{display:block;}
.loading_wrap{display:none;position:fixed;z-index:10000;top:0;left:0;height:100%;width:100%;background:rgba(0,0,0,.8) 50% 50% no-repeat;}
.loading_wrap .loading_spinner{animation:animRotate 1s linear infinite;background:transparent;border:4px solid #888;border-bottom-color:#fff;border-radius:50%;height:50px;left:50%;margin:-25px 0 0 -25px;opacity:.7;padding:0;position:absolute;top:50%;width:50px;z-index:10001;}
@keyframes animRotate{to{transform:rotate(1turn)}}
.loading_wrap{background:rgba(255,255,255,.4) 50% 50% no-repeat;}
.loading_inner{background:#fff;box-shadow:0 0 10px rgba(0, 0, 0, 0.3);padding:10px;width:80px;height:80px;border-radius:8px;position:absolute;z-index:10001;left:50%;top:50%;transform:translate(-50%, -50%);}
.loading_wrap .loading_spinner{animation:animRotate 3s linear infinite;border:4px dotted #000;border-bottom-color:#b10d28;height:60px;margin:-30px 0 0 -30px;width:60px;opacity:0.8;}
.loading_inner p{font-size:9px;margin:25px 0 0 9px;}   
/*Loading end*/

.payment_options_blk{margin-bottom:15px;}
.payment_method_radio{display:flex;justify-content:space-between;}
.payment_method_radio>div{display:inline-flex;align-items:center;}
.payment_method_radio .form-check input{margin-right:10px;}

/*Payments pages end*/
@media (min-width: 768px) {
    .footer .footer_menu_title svg{display:none;}        
    .footer_menu_title h3::before{position:absolute;bottom:-3px;left:20px;width:35px;height:4px;content:"";display:inline-block;background-color:#b10d28;}
    .footer_menu_title h3::after{position:absolute;bottom:-1px;left:0;width:80px;height:1px;content:"";display:inline-block;background-color:#b10d28;}
    .footer_menu_title h3{margin-bottom:25px;border-bottom:1px solid #e2e2e2;border-bottom-color:#3a3a3a;}
    .serv_box:hover .serv_box_inr::after{transition:0.5s;width:100%;height:100%;border-radius:10px;}
    .serv_box:hover .serv_content h4, .serv_box:hover .serv_content p{color:#fff;transition: 0.5s;}
    .serv_box .serv_icon img{transform:rotateY(0);transition:.3s linear;}
    .serv_box:hover .serv_icon img{transform:rotateY(180deg);}
    .inner_main_content{padding-left:20px;}
    .sales-error-box .card-body {min-width:550px;}
}
@media(max-width:1400px){
    .header_nav ul li a{font-size: 14px;padding:0 10px;}
    .inner_hero_txt_blk{text-align:center;}
}
@media(max-width:992px){.logo_toggle_wrap .logo_wrap{text-align:center;}}
@media(max-width:991px){
    .menu_desktop{position:absolute;right:0;left:auto;top:80px;width:100%;background:#fff;z-index:5;display:none;border-top:4px solid #E5E5E5;}
    .header_nav ul{height:auto;flex-direction:column;}
    .header_nav ul li a{font-size:14px;padding:10px;line-height:normal;border-left:none;width:100%;}
    .mobile_menu_toggle{display:flex;justify-content:center;align-items:center;height:20px;width:20px;position:relative;cursor:pointer;}
    .mobile_menu_toggle span{display:block;position:absolute;height:2px;width:20px;background-color:#2b2b2b;border-radius:1px;opacity:1;-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    .mobile_menu_toggle span:first-child{top:0;}
    .mobile_menu_toggle span:nth-child(2), .mobile_menu_toggle span:nth-child(3){top:7px;width:15px;}
    .mobile_menu_toggle span:nth-child(4){top:14px;}
    .mobile_menu_toggle.open span:nth-child(1), .mobile_menu_toggle.open span:nth-child(4){opacity:0;}
    .mobile_menu_toggle.open span:nth-child(2){-webkit-transform:rotate(45deg);transform:rotate(45deg);width:20px;}
    .mobile_menu_toggle.open span:nth-child(3){-webkit-transform: rotate(-45deg);transform:rotate(-45deg);width:20px;}
    .logo_toggle_wrap{display:flex;justify-content:space-between;align-items:center;}
    .services_blk .row > div, .services_blk .serv_box{margin-bottom:30px;}
    .info_ad_box p{font-size:30px;}
    .info_blk{padding-top:0;}
    .inner_main_blk{margin-bottom:50px;}
    .help_vid_blk .vid_item{width:100%;}
    .help_pg + .inner_main_blk, .contact_pg + .inner_main_blk{padding-bottom:0;}
}
@media (max-width:767px) {
    .footer_top, .info_blk{padding:30px 0;}
    .hero_txt_blk h1, .inner_hero_txt_blk h1{font-size:30px;}
    .lead_form_wrap h3, .sidebar_heading h3, .main_content_blk h3, .inner_main_content .faq_box .faq_sec_title h3, .legal_main .inner_main_content h4, .payment_summary_content h1{font-size:20px;}
    .legal_main .inner_main_content ul li, .legal_main .inner_main_content p{font-size:14px;}
    .footer_menu_title{display:flex;align-items:center;justify-content:space-between;cursor:pointer;}
    .footer_menu_title svg{background-color:#777;border-radius:30px;padding:5px;width:20px;height:20px;-webkit-transition:all .25s;transition:all .25s;cursor: pointer;}
    .footer_menu_title svg path{fill:#fff;}
    .open_footer .footer_menu_title svg{-webkit-transform:rotate(45deg);transform:rotate(45deg);}
    .footer_menu_title h3{margin-bottom:0;padding:0;font-size:16px;}
    .footer_menu_blk{margin-top:10px;background:#1b1f22;padding:10px 20px;border-radius:10px;}
    .footer .footer_mob_blk{display:none;border-top:1px solid #353c42;margin-top:10px;padding-top:10px;}
    .footer .footer_mob_blk.show{display: block;}
    .ft_btm_content, .payment_method_radio{flex-direction:column;}
    .legal_links ul li{margin:5px 10px 0 10px;}
    .info_ad_box p{font-size:24px;font-weight:700;line-height:normal;}        
    .lead_form_blk{top:0;padding:30px 0 70px 0;}
    .contact_btn_wrap{margin-top:15px;}
    .contact_btn_wrap a{margin:0;}
    .hero_blk{padding:30px 0 50px 0;}
    .lead_form_wrap{padding:20px;}
    .lead_form_wrap .lead_form{align-items:flex-start;flex-direction:column;}
    .lead_form .form-group{margin-right:0;margin-bottom:10px;}
    .services_blk .row>div:last-child, .services_blk .row>div:last-child .serv_box, .inner_main_blk{margin-bottom:0px;}
    .menu_desktop{top:85px;}
    .stickyHeader .menu_desktop{top:80px;border-top:4px solid #E5E5E5;}
    .header_nav{padding:0;background:#f2f2f2;}
    .header_nav ul li a{background:transparent;}
    .header_nav ul li{border-bottom:1px solid #e5e5e5;}
    .header_nav ul li:last-child{border-bottom:none;}
    .inner_hero_blk{min-height:200px;}
    .inner_main_blk{padding-top:30px;}
    .sidebar_heading h3{text-align:center;}
    .inner_main_content, .inner_main_content .faq_box, .more_info_blk{margin-top:30px;}
    .sidebar_wrap .sidebar_item, .contact_us_blk, .contact_main{margin-bottom:30px;}
    .help_pg + .inner_main_blk{padding-bottom:0;}
    .contact_main .row > div:last-child{order:1;}
    .contact_main .row > div:first-child{order:2;}
    .contact_main .inner_main_content{margin-top:0;}
    .contact_us_blk{padding:20px;}
    .help_vid_blk .vid_item iframe{height:200px;}
    .main_content_blk img{margin-bottom:20px;margin-top:5px;}
    .legal_main .inner_main_content{padding:20px;margin-bottom:30px;margin-top:0;}
    .legal_main .inner_main_content .main_content_blk h4{margin:10px 0;}
    .legal_main .main_content_blk ul{margin-bottom:10px;padding-left:15px;}
    .sales-error-box .card-title h1{font-size:6rem;}
    .sales-error-box .card-logo{width: auto;}
    .payment_summary_content, .payment_link_content{padding:20px;}
    .blk_title{font-size:18px;}
    .payment_summary_blk{margin:10px auto 30px auto;}
    .order_info_blk{padding:15px;}
    .personal_info ul{flex-wrap:wrap;}
    .personal_info ul li{width:100%;margin-right:0;margin-bottom:10px;}
    .personal_info ul li:last-child{margin-bottom:0;}
    .bs-stepper-header::before, .bs-stepper-header::after{width:76%;}
    .contact_method_blk h5, .contact_method_radio .form-check label, .account_number_blk h5{font-size:16px;}
    .payment_link_blk{padding:10px 0 30px 0;}
    .payment_link_content{margin:0px auto 30px auto;}
    .success_tick{width:40px;height:40px;margin:10px auto;}
    .success_tick img{max-width:30px;}
    .bs-stepper-header{margin:0px auto;}
    .thank_you_blk{padding:20px 10px;}
    .payment_link_content .form-group label{font-size:14px;}
}
@media (max-width:576px){
    .order_info ul{flex-wrap:wrap;}
    .order_info ul li{width:100%;margin-right:0;margin-bottom:10px;}
    .order_info ul li:last-child{margin-bottom:0;}
    .saved_card_list ul li{width:100%;padding:10px;}
    .account_number_blk .row>div:last-child .form-group{margin-top:15px;}
    .payment_method_radio .form-check label{font-size:13.5px;}
}
@media (max-width:560px){    
    .bs-stepper-header::before, .bs-stepper-header::after{width:70%;}
}
@media (max-width:440px){    
    .bs-stepper-header::before, .bs-stepper-header::after{width:62%;}
}
@media (max-width:360px){    
    .bs-stepper-header::before, .bs-stepper-header::after{width:55%;}
}
@media (max-width:360px){
    .legal_links ul li{margin:0 5px;}
    .legal_links ul li a{font-size:12px;}
    .hero_txt_blk h1, .inner_hero_txt_blk h1{font-size:26px;}
}

/*Home page version 2 start*/
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');
.home_v2 .header_nav ul{justify-content:flex-end;}
.home_v2 .slomins_site_link .header_nav ul li a{border:1px solid #fff;line-height:normal;padding:10px 20px;border-radius:100px;}
.home_v2 .slomins_site_link .header_nav ul li a{text-transform:none;background:#b10d28;color:#fff;border:1px solid #b10d28;}
.home_v2 .slomins_site_link .header_nav ul li a:hover{background:#fff;color:#b10d28;}
.home2_menu_wrap .header_nav ul li a, .home2_menu_wrap .header_nav ul li a:hover{border:none;text-transform:capitalize;}
.home_v2 .header{position:relative;}
.home_v2 .header.stickyHeader{position:fixed;}
.home2_hero_blk{padding:20px 0;background:#f2f2f2;border-top:0px solid #E5E5E5;}
.hero_content_wrap{display:flex;align-items:center;justify-content:space-between;}
.hero_content_left .hero_txt_blk h1{font-family:"Exo 2", sans-serif;font-size:60px;color:#2b2b2b;}
.hero_content_left .hero_txt_blk h1 span{font-weight:700;color:#b10d28;}
.hero_content_left .hero_txt_blk p{color:#2b2b2b;font-size:18px;}
.hero_content_left{width:50%;padding-right:50px;padding-left:50px;margin-bottom:20px;}
.hero_content_right{width:50%;text-align:right;}
.home_v2 .lead_form_wrap .lead_form{display:block;}
.home_v2 .lead_form_wrap .lead_form .form-group{margin-bottom:20px;}
.home_v2 .lead_form_inr{align-items:center;}
.home_v2 .lead_form_wrap .lead_form .form-group .form-control{padding:10px;}
.home_v2 .lead_form_wrap h3{font-size:30px;margin-bottom:20px;}
.home_v2 .lead_form_wrap{padding:50px;}
.form_left_blk{padding-top:80px;padding-right:50px;}
.home_v2 .red_btn{padding:10px 30px;}
.form_left_blk h2, .section_title h2{font-size:30px;font-weight:normal;}
.form_left_blk h2 span, .section_title h2 span{color:#b10d28;font-weight:700;}
.form_left_blk ul{list-style:none;padding-left:0;margin-bottom:0;}
.form_left_blk ul li{padding-left:28px;background:url('../public/img/tick_icon_list.svg') no-repeat 0 7px;margin-bottom:10px;}
.form_left_blk ul li:last-child{margin-bottom:0;}
.home_v2 .lead_form_wrap .small_txt{margin-bottom:0;}
.home_security_blk{background:#f2f2f2;padding:40px 0 50px 0;}
.serv_box_title{display:flex;align-items:center;justify-content:flex-start;}
.home_v2 .serv_box_title .serv_icon{margin:0;margin-right:20px;}
.home_v2 .serv_box .serv_content p{text-align:left;}
.section_title{text-align:center;width:100%;margin-bottom:30px;}
.btn_wrap{width:100%;text-align:center;margin-top:15px;}
.home_security_blk .serv_box{margin-bottom:25px;}
.customer_reviews_blk{padding:40px 0 0 0;}
.customer_review_wrap{display:flex;padding:50px 0;position:relative;}
.customer_review_wrap::after{content:'';width:calc(100% - 350px);height:100%;background:#f2f2f2 url('../public/img/quote-right.svg') no-repeat right 30px bottom 30px;position:absolute;top:0;left:350px;z-index:-1;border-radius:20px;background-size:180px;}
.customer_review_img img{max-width:450px;}
.customer_review_img{display:flex;}
.customer_reviews{padding:0 50px;width:calc(100% - 450px);display:flex;align-items:center;}
.customer_reviews_blk .review_item .stars ul{list-style:none;display:flex;padding-left:0;margin-bottom:10px;}
.customer_reviews_blk .review_item .stars ul li{margin-right:5px;}
.customer_reviews_blk .review_item .stars ul li:last-child{margin-right:0;}
.customer_reviews_blk .review_item .stars ul li .fa-star{color:#ff9430;font-size:20px;}
.customer_reviews_blk .review_txt p{margin-bottom:20px;}
.customer_reviews_blk .review_txt p:last-child{margin-bottom:0;}
.customer_reviews_blk .slick-initialized .slick-slide{display:inline-block;}
.customer_reviews .slick-prev {background:#b10d28 url("../public/img/arrow-prev.svg") no-repeat center center;border:1px solid #b10d28;font-size: 0px;width: 40px;height: 40px;position: absolute;left:50px;bottom:30px;z-index: 1;cursor: pointer;transform: translateY(-50%);box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);border-radius: 50px;}
.customer_reviews .slick-next {background:#b10d28 url("../public/img/arrow-next.svg") no-repeat center center;border:1px solid #b10d28;font-size: 0px;width: 40px;height: 40px;position: absolute;left:100px;bottom:30px;z-index: 1;cursor: pointer;transform: translateY(-50%);box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);border-radius: 50px;}
.customer_reviews .slick-prev:hover{background:#fff url("../public/img/arrow-prev-hover.svg") no-repeat center center;}
.customer_reviews .slick-next:hover{background:#fff url("../public/img/arrow-next-hover.svg") no-repeat center center;}
.home2_info_blk{padding:50px 0 0 0;}
.home_v2 .footer{margin-top:-50px;}
.home_v2 .footer .footer_top{padding-top:100px;}
.home2_info_blk .info_ad_box{border:2px solid #fff;}
.home_v2 .home_security_blk .serv_box .serv_box_title h4{margin-bottom:0;}

@media(min-width:768px){
    .home_v2 .serv_box:hover .serv_box_title h4{color:#fff;}
}
@media(min-width:1921px){
    .hero_content_wrap{max-width:1340px;margin:0 auto;padding-left:0;padding-right:0;}   
    .hero_content_left{padding-left:25px;}
    .hero_content_right{padding-right:25px;}
    .hero_content_left .hero_txt_blk h1 br{display:none;}
}
@media(max-width:1399.5px){
    .hero_content_left .hero_txt_blk h1 br{display:none;}
}
@media(max-width:1199.5px){
    .home_v2 .header_nav ul li a{padding:0 5px;}
    .hero_content_left .hero_txt_blk h1{font-size:44px;}
    .hero_content_left .hero_txt_blk p{font-size:16px;}
    .hero_content_left{padding-right:30px;}
    .home_v2 .lead_form_wrap{padding:25px;}
    .form_left_blk{padding-top:120px;}
    .home_v2 .lead_form_wrap h3{font-size:20px;}
    .small_txt{font-size:10px;}
}
@media(max-width:991.5px){
    .slomins_site_link, .customer_review_wrap::after, .customer_review_img{display:none;}
    .home_v2 .lead_form_blk .lead_form_inr>div:first-child{order:2;}
    .home_v2 .lead_form_blk .lead_form_inr>div:last-child{order:1;}
    .form_left_blk{padding:30px 0;}
    .home_v2 .lead_form_wrap .lead_form{display:flex;}
    .home_v2 .lead_form_wrap .lead_form .form-group{margin-bottom:0;margin-right:10px;}
    .home_v2 .lead_form_wrap .lead_form .form-group .form-control{padding:7px 10px;}
    .home_v2 .lead_form_wrap .lead_form .red_btn{padding:7px 15px;}
    .home_v2 .lead_form_wrap h3{margin-bottom:15px;}
    .home2_hero_blk{padding:20px 0 100px 0;}
    .home_v2 .header_nav ul li a{padding:10px;}
    .hero_content_left .hero_txt_blk h1{font-size:36px;}
    .home_v2 .home_security_blk .serv_box{margin-bottom:0;}
    .home_v2 .serv_box_inr{text-align:left;}
    .home_v2 .serv_box_title .serv_icon{margin-right:15px;width:50px;height:50px;padding:10px;min-width:50px;}
    .home_security_blk .row:first-child > div, .home_security_blk .row:last-child > div{margin-bottom:0;}
    .home_security_blk .section_title{margin-bottom:20px;}
    .home_security_blk .btn_wrap{margin-top:0;}
    .home_v2 .home_security_blk .serv_box .serv_box_title h4{font-size:20px;}
    .customer_review_wrap{background:#f2f2f2 url('../public/img/quote-right.svg') no-repeat right 20px bottom 20px;border-radius:10px;padding:0;background-size:70px;}
    .customer_reviews{padding:25px 40px;width:100%;}
    .customer_reviews .slick-prev{left:-20px;bottom:auto;top:50%;transform:translateY(-50%);}
    .customer_reviews .slick-next{left:auto;bottom:auto;top:50%;transform:translateY(-50%);right:-20px;}
}
@media(max-width:767.5px){
    .home2_hero_blk, .lead_form_blk, .home_security_blk{padding:30px 0;}
    .form_left_blk, .customer_reviews_blk, .home2_info_blk{padding:30px 0 0 0;}
    .section_title{margin-bottom:15px;}
    .hero_content_right{display:none;}
    .hero_content_left{width:100%; padding:0 12px;margin-bottom:0;}
    .hero_content_left .hero_txt_blk h1{font-size:30px;}
    .form_left_blk h2, .section_title h2{font-size:24px;}
    .home_v2 .lead_form_wrap .lead_form .form-group{margin-bottom:10px;margin-right:0;}
    .home_security_blk .row > div, .home_security_blk .serv_box{margin-bottom:20px;}
    .home_security_blk .btn_wrap{margin-top:20px;}
    .home_v2 .lead_form_wrap .lead_form .red_btn{padding:10px 25px;}
    .home_security_blk .section_title h2{margin-bottom:0;}
    .home_security_blk .serv_content{margin-top:10px;}
    .home_v2 .footer .footer_top{padding-top:80px;}
    .home_v2 .lead_form_wrap{padding:20px;}
}
@media(max-width:576.5px){
    .customer_reviews .slick-prev{left:-10px;}
    .customer_reviews .slick-next{right:-10px;}   
}
/*Home page version 2 end*/